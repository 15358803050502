.profile-container {
  display: block;
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: nowrap;
  }
  .profile-items {
    .profile-wrapper {
      @media (min-width: 1024px) {
        margin-right: 102px;
      }
      max-width: 336px;
    }
  }
  .grid-item {
    margin-bottom: 24px;
  }
}
.stats-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -12.5px;
  .item {
    flex: 0 0 33.33%;
    padding: 0 12.5px;
  }
}
.credit-container {
  // display: flex;
  margin: 0 -10px;
  // .item {
  //   flex: 0 0 50%;
  //   padding: 0 10px;
  // }
}
