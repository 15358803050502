.topic-main {
  min-height: calc(100vh - 329px);
  // padding-top: 60px;
  .btn-search {
    background: transparent linear-gradient(90deg, #aa2217 0%, #db2718 100%) 0%
      0% no-repeat padding-box;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  // .btn-return-home {
  //   align-items: center;
  //   background: #00000065 0% 0% no-repeat padding-box;
  //   column-gap: 0.5rem;
  //   display: inline-flex;
  //   border-radius: 16px;
  //   padding: 4px 7px;
  //   .circle-back {
  //     background: #aa2217 0% 0% no-repeat padding-box;
  //     padding: 8px 9px;
  //     width: 24px;
  //     border-radius: 24px;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
  // .grid-2-column {
  // display: grid;
  // gap: 29px;
  // margin-top: 50px;
  // margin-bottom: 83px;
  // grid-template-columns: 1fr 1fr;
  .box-popular {
    grid-column: 1;
    grid-row: 1;
    // background: transparent linear-gradient(180deg, #f7f7f7 0%, #e9ecf0 100%)
    //   0% 0% no-repeat padding-box;
    background-image: url(/frontend/img/bg_popular_blog.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    padding: 124px 16px 16px 16px;
    position: relative;
    @media (min-width: 1024px) {
      padding: 124px 45px 66px 30px;
    }
    /* width */
    .box-scroll {
      padding-right: 0;
      @media (min-width: 1024px) {
        padding-right: 35px;
        overflow-y: auto;
        max-height: 620px;
      }
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #fff;
        background: #fff;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
        border: 7px solid #aa2217;
      }
    }

    .badge-popular {
      background: transparent linear-gradient(90deg, #000000 0%, #aa2217 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 15px #00000026;
      border-radius: 0px 30px 30px 0px;
      position: absolute;
      left: -5px;
      top: 30px;
      padding: 15px 40px;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        margin-right: 16px;
      }
    }
    .gird-2-overflow {
      margin-bottom: 23px;
      .item {
        .user-profile-frame {
          margin-right: 7px;
          padding-top: 7px;
          .logo_team {
            display: flex;
            justify-content: center;
            img {
              width: 8px;
            }
          }
          .profile_frame {
            width: 92px;
            height: 92px;
            background-position: center center;
            background-size: contain;
            position: relative;
            margin: 0 auto;
            @media (min-width: 991px) {
              margin: 0;
            }
            .profile_inner {
              position: absolute;
              top: 8px;
              left: 26px;
              right: 26px;
              bottom: 24px;
              text-align: -webkit-center;
              .profile_type {
                // background: #ffffff 0% 0% no-repeat padding-box;
                // border-radius: 12px;
                // padding: 3px 14px;
                // width: fit-content;
                display: flex;
                justify-content: center;
                img {
                  width: 5px;
                }
                color: #fff;
                text-align: center;
                margin-bottom: 1px;
                line-height: 1;
              }
              .profile-name {
                font-size: 6px;
                margin-bottom: 1px;
                line-height: 1;
              }
              .profile_picture {
                // margin-bottom: 5px;

                .profile_picture_outer {
                  position: relative;
                  width: 40px;
                  height: 40px;
                  &:before {
                    display: block;
                    content: "";
                    width: 100%;
                    padding-top: calc(1 / 1) * 100%;
                  }
                  > .profile_picture_inner {
            background-image: url(/frontend/img/pic_defaultprofile@2x.png);

                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    background-color: #ffffff;

                    background-position: center;
                    background-size: cover;
                    // transform: translateX(-50%) translateY(-50%);
                  }
                }
              }
            }
          }
        }
        .box-topic-lists {
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 10px;
          padding: 8px 24px 12px 24px;
          position: relative;
          margin-top: 7px;
          .view-num {
            // position: absolute;
            // top: 12px;
            // right: 19px;
            font-size: 12px;
            color: #a8a8a8;
            display: flex;
            align-items: center;
            img.view-eye {
              width: 20px;
            }
          }
          .title {
            margin-bottom: 16px;
          }
          .bottom-left {
            // display: flex;
            font-size: 12px;
            .date {
              // margin-right: 23px;
              display: flex;
              align-items: center;
              img {
                width: 10px;
                margin-right: 5px;
              }
            }
            .create-by {
              // display: flex;
              align-items: center;
              img {
                width: 10px;
                // margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .box-new-topic {
    grid-column: 2;
    grid-row: 1;
    margin-top: 30px;
    .badge-new-topic {
      background: transparent
        linear-gradient(90deg, #000000 0%, #961e13 53%, #db2718 100%) 0% 0%
        no-repeat padding-box;
      border-radius: 35px;
      padding: 15px;
      min-width: 100%;
      @media (min-width: 1024px) {
        min-width: 417px;
      }
      text-align: center;
      // width: fit-content;
      cursor: pointer;
    }
    .box-search-topic {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 25px rgba(161, 163, 178, 0.2039215686);
      border: 0.5px solid #ededed;
      border-radius: 20px;
      margin-top: 45px;
      padding: 35px 40px;
      // width: 417px;
      height: fit-content;
      text-align: -webkit-center;

      .line-w-text {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dcdeed;
        line-height: 0.1em;
        margin: 10px 0 20px;
      }

      .line-w-text span {
        background: #fff;
        padding: 0 10px;
      }
      // .grid-hashtag {
      // display: flex;
      // flex-flow: wrap;
      // justify-content: center;
      .box-hashtag {
        border: 1px solid #d7dae2;
        border-radius: 50px;
        // width: fit-content;
        padding: 7px 12px;
        // font-size: 10px;
        margin: 5px;
        a {
          color: #62646a;
        }
        // cursor: pointer;
      }
      // }
    }
  }
  // }
  .topic-type {
    text-align: center;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 29px;
  }
  .gird-topic-type {
    // display: grid;
    // grid-template-columns: repeat(5, minmax(0, 1fr));
    // gap: 30px;
    margin-bottom: 85px;
    .box-type {
      background: transparent linear-gradient(180deg, #fcfcfc 0%, #e8e8e8 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 10px #a1a3b227;
      border: 0.5px solid #ededed;
      border-radius: 15px;
      padding: 15px 30px;
      display: flex;
      align-items: center;
      height: 100%;
      &.active {
        color: #fff;
        background: transparent
          linear-gradient(180deg, #db2718 0%, #961e14 100%) 0% 0% no-repeat
          padding-box;
        .icon-type {
          background: #fff;
          img {
            filter: none;
          }
        }
      }
      &:hover {
        color: #fff;
        background: transparent
          linear-gradient(180deg, #db2718 0%, #961e14 100%) 0% 0% no-repeat
          padding-box;
        .icon-type {
          background: #fff;
          img {
            filter: none;
          }
        }
      }
      .icon-type {
        background: transparent
          linear-gradient(180deg, #db2718 0%, #aa2217 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 40px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-right: 15px;
        img {
          filter: brightness(0) invert(1);
          width: 20px;
        }
      }
    }
  }
  .swiper-scrollbar {
    width: 25% !important;
    left: 38% !important;
  }
  .swiper-scrollbar-drag {
    background: #aa2217;
  }
  .box-type {
    background: transparent linear-gradient(180deg, #fcfcfc 0%, #e8e8e8 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 10px 10px #a1a3b227;
    border: 0.5px solid #ededed;
    border-radius: 15px;
    padding: 12px;
    display: flex;
    align-items: top;
    height: 100%;
    font-size: 12px;
    @media (min-width: 1024px) {
      font-size: 16px;
      padding: 15px 30px;
      align-items: center;
    }
    &.active {
      color: #fff;
      background: transparent linear-gradient(180deg, #db2718 0%, #961e14 100%)
        0% 0% no-repeat padding-box;
      .icon-type {
        background: #fff;
        img {
          filter: none;
        }
      }
    }
    &:hover {
      color: #fff;
      background: transparent linear-gradient(180deg, #db2718 0%, #961e14 100%)
        0% 0% no-repeat padding-box;
      .icon-type {
        background: #fff;
        img {
          filter: none;
        }
      }
    }
    .icon-type {
      background: transparent linear-gradient(180deg, #db2718 0%, #aa2217 100%)
        0% 0% no-repeat padding-box;
      border-radius: 40px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-right: 15px;
      img {
        filter: brightness(0) invert(1);
        width: 20px;
      }
    }
  }
  .box-news {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 25px #a1a3b233;
    border: 0.5px solid #ededed;
    border-radius: 40px;
    padding: 141px 22px 22px 22px;
    // margin-bottom: 141px;
    position: relative;
    @media (min-width: 1028px) {
      padding: 141px 95px 57px 95px;
    }
    &.search {
      padding: 22px;
      @media (min-width: 1028px) {
        padding: 50px 95px 53px 95px;
      }
    }
    .badge-news {
      background: transparent linear-gradient(90deg, #000000 0%, #aa2217 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 15px #00000026;
      border-radius: 0px 30px 30px 0px;
      position: absolute;
      left: -5px;
      top: 30px;
      padding: 15px 40px;
      // display: flex;
      align-items: center;
      .icon-type {
        background: #ffffff40 0% 0% no-repeat padding-box;
        padding: 10px;
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          filter: brightness(0) invert(1);
        }
      }
    }
    .grid-news {
      // display: grid;
      // grid-template-columns: repeat(2, minmax(0, 1fr));
      // gap: 0 21px;
      margin-bottom: 45px;
      // &.less {
      //   grid-template-columns: repeat(1, minmax(0, 1fr));
      // }
      .item-news {
        margin-bottom: 18px;
        &.border-bottom-dash {
          padding-bottom: 18px;
          border-bottom: 1px dashed #ccc;
        }
        .topic-news {
          display: flex;
          margin-bottom: 12px;
          .bg-news {
            .news-outer {
              position: relative;
              .pin{
                position: absolute;
                top: -10px;
                right: -10px;
                z-index: 1;
                img{
                  width: 20px;
                  height: 20px;
                }
              }
              &:before {
                display: block;
                content: "";
                width: 100%;
                padding-top: calc(1 / 1) * 100%;
              }
              > .news-inner {
                position: absolute;
                top: 50%;
                right: 0;
                bottom: 0;
                left: 50%;
                width: 100%;
                height: 100%;
                // background: transparent;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                transform: translateY(-50%) translateX(-50%);
                // border-radius: 10px;
                // overflow: hidden;
              }
            }
            width: 50px;
            height: 50px;
            background: #ccc;
            border-radius: 2px;
            margin-right: 13px;
            flex: 0 0 50px;
            overflow:inherit;
          }
          .title-news {
            font-size: 16px;
          }
        }
        .footer-news {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          .footer-left {
            @media (min-width: 768px) {
              display: flex;
            }
            .date {
              margin-right: 23px;
              display: flex;
              align-items: center;
              img {
                width: 10px;
                margin-right: 5px;
              }
            }
            .create-by {
              display: flex;
              align-items: center;
              img {
                width: 10px;
                margin-right: 5px;
              }
            }
          }
          .footer-right {
            display: flex;
            align-items: center;
            .view-num {
              margin-right: 15px;
              display: flex;
              align-items: center;
              img {
                width: 20px;
                margin-right: 5px;
              }
            }
            .comment-num {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .grid-topic-create {
    // display: grid;
    // grid-template-columns: auto 1fr;
    // gap: 30px;
    input.form-control {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    .create-type {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 25px #0000001a;
      border-radius: 30px;
      padding: 41px 24px;
      width: 100%;
      height: fit-content;
      @media (min-width: 1024px) {
        width: 370px;
        padding: 41px 33px;
      }
      .grid-2-type {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 15px;
        .check-with-label:checked + .label-for-check {
          .create-type-box {
            background: transparent
              linear-gradient(180deg, #db2718 0%, #aa2217 100%) 0% 0% no-repeat
              padding-box;
            .icon-type {
              background: #ffffff;
              img {
                filter: none;
              }
            }
            .type-name {
              color: #fff;
            }
          }
        }
        .create-type-box {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #d7dae2;
          border-radius: 10px;
          padding: 20px 10px;
          display: flex;
          align-items: center;
          height: 100%;
          @media (min-width: 768px) {
            padding: 20px 15px;
          }
          &:hover {
            background: transparent
              linear-gradient(180deg, #db2718 0%, #aa2217 100%) 0% 0% no-repeat
              padding-box;
            .icon-type {
              background: #ffffff;
              img {
                filter: none;
              }
            }
            .type-name {
              color: #fff;
            }
          }
          .icon-type {
            width: 26px;
            height: 26px;
            flex: 0 0 26px;
            border-radius: 26px;
            background: transparent
              linear-gradient(180deg, #db2718 0%, #aa2217 100%) 0% 0% no-repeat
              padding-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 14px;
              filter: brightness(0) invert(1);
            }
          }

          .type-name {
            margin-left: 9px;
            font-size: 14px;
            color: #000;
          }
        }
      }
    }
    .create-detail {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 25px #0000001a;
      border-radius: 30px;
      padding: 30px 22px;
      margin-bottom: 70px;
      @media (min-width: 768px) {
        padding: 41px 42px;
      }
      .upload-label {
        border: 1px dashed #dcdeed;
        border-radius: 5px;
        // width: 100%;
        // height: 100%;
        position: relative;
        // @media (min-width: 1024px) {
        //   height: 138px;
        // }
        // &.thumb-topic{
        //   @media (min-width: 1024px) {
        //     height: 203px !important;
        //     width: 298px;
        //   }
        // }
        .preview-file {
          .preview-file-outer {
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: calc(69 / 320) * 100%;
            }
            > .preview-file-inner {
              position: absolute;
              top: 50%;
              right: 0;
              bottom: 0;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 100%;
              height: 100%;
            }
          }
        }
        .upload-file {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 100%;
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
      .input-label {
        width: 100%;
        // height: 240px;
        // border: 1px solid #dcdeed;
        border-radius: 5px;
      }
      .flex-create-hashtag {
        display: flex;
        justify-content: space-between;
        .add-hashtag {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.75px solid #ebebeb;
          border-radius: 50px;
          padding: 5px 22px;
          font-size: 10px;
        }
      }
      .box-hashtag {
        display: flex;
        flex-flow: wrap;
        .hashtag {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.75px solid #d7dae2;
          border-radius: 50px;
          width: fit-content;
          padding: 5px 10px;
          font-size: 10px;
          color: #62646a;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .btn-preview {
    border: 1px solid #dcdeed;
    border-radius: 5px;
    color: #bbc1ce;
    cursor: pointer;
    padding: 12px 18px;
    @media (min-width: 768px) {
      padding: 12px 38px;
    }
    &.active {
      border: 1px solid #aa2217;
      color: #aa2217;
    }
  }
  .btn-create {
    background: transparent linear-gradient(90deg, #db2718 0%, #aa2217 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #dcdeed;
    border-radius: 5px;
    padding: 12px 18px;
    @media (min-width: 768px) {
      padding: 12px 38px;
    }
    cursor: pointer;
    &.disabled {
      background: #adadad 0% 0% no-repeat padding-box;
    }
  }

  .thumb-cover {
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    @media (min-width: 768px) {
      border-radius: 20px;
    }
    .thumb-cover-outer {
      position: relative;
      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(46 / 225) * 100%;
      }
      > .thumb-cover-inner {
        position: absolute;
        top: -50%;
        right: 0;
        left: -50%;
        bottom: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateX(50%) translateY(50%);
        width: 100%;
        height: 100%;
      }
    }
  }

  .line-w-text {
    width: 100%;
    border-bottom: 2px dashed #dcdeed;
    margin: 0 0 63px;
    position: relative;
  }

  .comment-this {
    position: absolute;
    top: -20px;
    left: 107px;
    background: #fff;
    padding: 0 19px;
    .badge-comment {
      background: transparent linear-gradient(270deg, #db2718 0%, #aa2217 100%)
        0% 0% no-repeat padding-box;
      border-radius: 23px;
      padding: 9px 16px;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      @media (min-width: 768px) {
        padding: 9px 33px;
        font-size: 14px;
      }
      img {
        width: 14px;
        height: 14px;
      }
      .ms-9px {
        margin-left: 9px;
      }
    }
  }
  .box-commemt {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 25px #a1a3b233;
    border-radius: 30px;
    padding: 31px 20px;
    @media (min-width: 768px) {
      padding: 31px 39px;
    }
    .mb-23px {
      margin-bottom: 23px;
    }
    .box-ls-content {
      background: #eff0f4 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 20px 20px;
      @media (min-width: 768px) {
        padding: 20px 40px;
      }
      .user {
        // display: flex;
        justify-content: center;
      }
      .badge-type {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 3px 5px 40px #00000027;
        border-radius: 35px;
        padding: 3px 12px;
        line-height: 10px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        width: fit-content;
        .icon-badge-type {
          img {
            width: auto;
            height: 15px;
            margin-right: 2px;
          }

          // flex: 0 0 5px;
        }
        span {
          font-size: 10px;
          @media (min-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .comment {
        font-family: escapeSingleQuotTimes New RomanescapeSingleQuot, serif !important;
        line-break: anywhere;
      }
    }
    .verify-date {
      img {
        width: 10px;
        height: 10px;
        @media (min-width: 768px) {
          width: 15px;
          height: 15px;
        }
      }
      display: flex;
      align-items: center;
      font-size: 12px;
      .ms-3px {
        margin-left: 3px;
      }
    }
    .me-12px {
      margin-right: 12px;
    }
    .comment-amount {
      img {
        width: 10px;
        height: 10px;
        @media (min-width: 768px) {
          width: 15px;
          height: 15px;
        }
      }
      .ms-3px {
        margin-left: 3px;
      }
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    .box-reply {
      background: #eff0f4 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 20px 30px;
      @media (min-width: 768px) {
        padding: 20px 40px;
      }
      // margin-left: 303px;
      position: relative;
      .icon-ls-dropdown {
        position: absolute;
        right: 28px;
        top: 45%;
        img {
          width: 11px;
        }
      }
      &.p-6px {
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        cursor: pointer;
      }
      .reply-text {
        font-family: escapeSingleQuotTimes New RomanescapeSingleQuot, serif !important;
        line-break: anywhere;
      }
      .me-7px {
        margin-right: 7px;
      }

      .user-reply {
        border-top: 1px solid #d7dae2;
        padding-top: 10px;
        .user-reply-name {
          margin-left: 0;
          @media (min-width: 768px) {
            margin-left: 18px;
          }
        }
      }
      .user-profile-frame {
        margin-right: 7px;
        .profile_frame {
          width: 60px;
          height: 60px;
          background-position: center center;
          background-size: contain;
          position: relative;
          margin: 0 auto;
          @media (min-width: 991px) {
            margin: 0;
          }
          .profile_inner {
            position: absolute;
            top: 6px;
            left: 17px;
            right: 17px;
            bottom: 14px;
            // border: red 1px solid;
            .logo_team {
              img {
                width: 6px;
              }
            }
            text-align: -webkit-center;
            .profile_type {
              // background: #ffffff 0% 0% no-repeat padding-box;
              // border-radius: 12px;
              // padding: 3px 14px;
              width: fit-content;
              text-align: center;
              margin-bottom: 2px;
              img {
                width: 3px;
              }
            }
            .profile-name {
              font-size: 4px;
              margin-bottom: 1px;
              line-height: 1;
            }
            .profile_picture {
              // margin-bottom: 5px;

              .profile_picture_outer {
                position: relative;
                width: 24px;
                height: 24px;
                &:before {
                  display: block;
                  content: "";
                  width: 100%;
                  padding-top: calc(1 / 1) * 100%;
                }
                > .profile_picture_inner {
                  position: absolute;
            background-image: url(/frontend/img/pic_defaultprofile@2x.png);

                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;

                  background-color: #ffffff;

                  background-position: center;
                  background-size: cover;
                  // transform: translateX(-50%) translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
    .user-profile-frame {
      margin-right: 7px;
      .profile_frame {
        @media (min-width: 768px) {
          width: 180px;
          height: 180px;
        }
        width: 70px;
        height: 70px;
        background-position: center center;
        background-size: contain;
        position: relative;
        margin: 0 auto;
        @media (min-width: 991px) {
          margin: 0;
        }
        .profile_inner {
          position: absolute;

          top: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          @media (min-width: 768px) {
            top: 15px;
            left: 52px;
            right: 52px;
            bottom: 48px;
          }
          text-align: -webkit-center;
          .logo_team {
            img {
              width: 5px;
              @media (min-width: 768px) {
                width: 16px;
              }
            }
          }
          .profile_type {
            // background: #ffffff 0% 0% no-repeat padding-box;
            // border-radius: 12px;
            // padding: 3px 14px;
            width: fit-content;
            text-align: center;
            margin-bottom: 5px;
            img {
              width: 5px;
              @media (min-width: 768px) {
                width: 15px;
              }
            }
          }
          .profile-name {
            font-size: 6px;
            margin-bottom: 2px;
            margin-top: 2px;
            line-height: 10px;
            @media (min-width: 768px) {
              font-size: 12px;
              line-height: 17px;
            }
          }
          .profile_picture {
            // margin-bottom: 5px;

            .profile_picture_outer {
              position: relative;
              width: 25px;
              height: 25px;
              @media (min-width: 768px) {
                width: 76px;
                height: 76px;
              }
              &:before {
                display: block;
                content: "";
                width: 100%;
                padding-top: calc(1 / 1) * 100%;
              }
              > .profile_picture_inner {
            background-image: url(/frontend/img/pic_defaultprofile@2x.png);

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background-color: #ffffff;

                background-position: center;
                background-size: cover;
                // transform: translateX(-50%) translateY(-50%);
              }
            }
          }
        }
      }
    }
    .comment-text {
      width: 100%;
      // margin-left: 45px;
      position: relative;
      padding-bottom: 90px;
      height: 100%;
      @media (min-width: 768px) {
        max-width: 791px;
        padding-bottom: 80px;
      }
      .time {
        img {
          width: 10px;
          height: 10px;
        }
        color: #a8a8a8;
      }

      .footer {
        position: absolute;
        bottom: 10px;
        left: 0;
        .mb-7px {
          margin-bottom: 7px;
        }
        .icon-like {
          display: flex;
          align-items: end;
          .like-total {
            // position: absolute;
            // top: 10px;
            // left: 38px;
            margin-bottom: -5px;
            font-size: 16px;
            color: #a8a8a8;
          }
          img {
            content: url("/frontend/img/icon_grey_like@2x.png");
            width: 30px;
            cursor: pointer;
          }
          &.clicked {
            .like-total {
              color: #7ac9d4;
            }
            img {
              content: url("/frontend/img/icon_like@2x.png");
            }
          }
        }
        .icon-unlike {
          // display: flex;
          // position: absolute;
          // top: 15px;
          // left: 68px;
          margin-bottom: -5px;

          img {
            content: url("/frontend/img/icon_grey_dislike@2x.png");
            width: 30px;
            cursor: pointer;
            margin-bottom: -5px;
          }
          .unlike-total {
            // position: absolute;
            // top: -4px;
            // left: 38px;
            font-size: 16px;
            color: #a8a8a8;
          }
          &.clicked {
            .unlike-total {
              color: #e45a45;
            }
            img {
              content: url("/frontend/img/icon_dislike@2x.png");
            }
          }
        }
        .note {
          color: #e45a45;
          font-size: 12px;
        }
      }
    }
  }
  .box-create-comment {
    background: #eff0f4 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 39px 35px;
    margin-bottom: 152px;
    .btn-regis-comment {
      border: 1px solid #aa2217;
      border-radius: 5px;
      padding: 12px 24px;
      font-size: 14px;
      color: #aa2217;
    }
    .btn-login-comment {
      border: 1px solid #aa2217;
      background-color: #aa2217;
      border-radius: 5px;
      padding: 12px 24px;
      font-size: 14px;
      color: #fff;
    }
    .box-text-comment {
      // background: #fff;
      // height: 285px;
    }
    .btn-submit {
      background: transparent linear-gradient(90deg, #aa2217 0%, #db2718 100%)
        0% 0% no-repeat padding-box;
      border-radius: 10px;
      padding: 8px 39px;
      width: fit-content;
      color: #fff;
      height: fit-content;
    }
    .user-profile-frame {
      margin-right: 7px;
      .profile_frame {
        width: 60px;
        height: 60px;
        background-position: center center;
        background-size: contain;
        position: relative;
        margin: 0 auto;
        @media (min-width: 991px) {
          margin: 0;
        }
        .profile_inner {
          position: absolute;
          top: 6px;
          left: 17px;
          right: 17px;
          bottom: 14px;
          // border: red 1px solid;
          .logo_team {
            img {
              width: 6px;
            }
          }
          text-align: -webkit-center;
          .profile_type {
            // background: #ffffff 0% 0% no-repeat padding-box;
            // border-radius: 12px;
            // padding: 3px 14px;
            width: fit-content;
            text-align: center;
            margin-bottom: 2px;
            img {
              width: 3px;
            }
          }
          .profile-name {
            font-size: 4px;
            margin-bottom: 1px;
            line-height: 1;
          }
          .profile_picture {
            // margin-bottom: 5px;

            .profile_picture_outer {
              position: relative;
              width: 24px;
              height: 24px;
              &:before {
                display: block;
                content: "";
                width: 100%;
                padding-top: calc(1 / 1) * 100%;
              }
              > .profile_picture_inner {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            background-image: url(/frontend/img/pic_defaultprofile@2x.png);

                background-color: #ffffff;

                background-position: center;
                background-size: cover;
                // transform: translateX(-50%) translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
  .search-topic {
    // display: flex;
    // align-items: center;
    .search {
      // margin-right: 20px;
      // flex: 0 0 330px;
      .search-box {
        position: relative;
        input.form-search {
          width: 100%;
          padding: 12px 20px 12px 45px;
          margin: 8px 0;
          display: inline-block;
          border: 1px solid #dcdeed;
          border-radius: 28px;
          box-sizing: border-box;
        }
        img {
          position: absolute;
          left: 8%;
          top: 40%;
        }
      }
    }
  }

  .search-box {
    position: relative;
    input.form-search {
      width: 100%;
      padding: 12px 20px 12px 45px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #dcdeed;
      border-radius: 28px;
      box-sizing: border-box;
    }
    img {
      position: absolute;
      left: 8%;
      top: 40%;
    }
  }

  .news-detail {
    // max-width: 662px;
    width: 100%;
    // margin-right: 47px;
    padding-bottom: 50px;
    position: relative;
  }
  .box-ads {
    width: 100%;
    @media (min-width: 1028px) {
      max-width: 305px;
    }
  }
}
#preview-content {
  width: 100%;
  .is-close-btn {
    display: none;
  }
  @media (min-width: 1028px) {
    width: 1128px;
  }
}
.box-news-content {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 10px 25px #a1a3b233 !important;
  border-radius: 20px !important;
  margin: -15px 0px 60px 0px !important;
  position: relative !important ;
  z-index: 1 !important;
  padding: 25px 22px;
  @media (min-width: 768px) {
    padding: 48px 46px 55px 46px !important;
    margin: -61px 0px 60px 0px !important;
  }
  .fix-h {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
  }
  .news-content {
    font-family: escapeSingleQuotTimes New RomanescapeSingleQuot, serif !important;
    font-size: 12px;
    line-break: anywhere;
    @media (min-width: 1024px) {
      font-size: inherit;
    }
  }
  .tag-news {
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 50px;
    padding: 9px 20px;
    color: #fff;
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: fit-content;
    line-height: 12px;
    .ms-8px {
      margin-left: 8px !important;
    }
    img {
      width: 12px !important;
    }
  }
  .tag-edit {
    background: #fff;
    // background: #aa2217 0% 0% no-repeat padding-box;
    border-radius: 50px;
    border-color: #aa2217;
    padding: 9px 20px;
    color: #aa2217;
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: fit-content;
    line-height: 12px;
    .ms-8px {
      margin-left: 8px !important;
    }
    img {
      width: 12px !important;
    }
  }
  .icon {
    width: 10px !important;
  }
  .icon-eye {
    width: 20px !important;
  }
  .footer {
    background: #ebecf0 0% 0% no-repeat padding-box !important;
    border-radius: 24px;
    padding: 8px 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    .btn-ls-back {
      border-radius: 16px;
      display: flex;
      padding: 4px 12px 4px 5px;
      @media (min-width: 768px) {
        background: #000000 0% 0% no-repeat padding-box;
      }
      .circle-arrow {
        width: 24px;
        height: 24px;
        background: #aa2217 0% 0% no-repeat padding-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        margin-right: 6px;
        img {
          width: 5px;
        }
      }
    }
    .group-btn {
      display: flex;
    }
  }
  // .modal-create{
  //   width: 100%;
  //   @media (min-width:768px) {
  //   max-width:570px;

  //   }
  // }
}
.box-ads {
  width: 305px;
  flex: 0 0 305px;
  .no-content {
    background-color: #c0c0c0;
    width: 305px;
    height: 460px;
    border-radius: 20px;
    overflow: hidden;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background: none !important;
}
.news-detail {
  // max-width: 662px;
  width: 100%;
  @media (min-width: 1024px) {
    margin-right: 47px;
  }
  padding-bottom: 50px;
  position: relative;
  img {
    max-width: 100%;
  }
  .respond-img {
    a {
      img {
        width: 20px;
        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
.btn-fan-cancle {
  background: transparent linear-gradient(90deg, #db2718 0%, #aa2217 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #dcdeed;
  border-radius: 5px;
  padding: 12px 38px;
  cursor: pointer;
  color: #fff;
  line-height: 1;
}
.time {
  img {
    width: 10px;
    height: 10px;
  }
  color: #a8a8a8;
}
.close-tab-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 10px 0;
}
.btn-reply {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 50px;
  padding: 3px 13px;
  font-size: 12px;
  width: fit-content;
  cursor: pointer;
  img {
    width: 10px;
    height: 10px;
  }
  .ms-7px {
    margin-left: 7px;
  }
}
.btn-edit-confirm {
  background: transparent linear-gradient(90deg, #db2718 0%, #aa2217 100%) 0% 0%
    no-repeat padding-box;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
}
.btn-edit-close {
  border-radius: 10px;
  color: #000;
  background: #fff;
}
.thumbnail-webboard {
  overflow: hidden;
  img {
    border-radius: 10px;
    --tw-border-opacity: 1;
    --tw-shadow: 0 3px 10px rgba(177, 181, 196, 0.5);
    --tw-shadow-colored: 0 3px 10px var(--tw-shadow-color);
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
    border-radius: 10px;
    border-width: 2px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    margin-bottom: 2rem;
    overflow: hidden;
  }
}
.thumb-webboard-outer {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (32 / 47) * 100%;
  }
  > .thumb-webboard-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

