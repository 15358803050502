@import "profile";
@import "grid";
@import "auth";
@import "modal";
@import "topic";
@import "accessory";
@import "vote";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: Kanit, sans-serif !important;
}
.w-50px {
  max-width: 50px;
}
#header {
  z-index: 9;
}
// .w-100 {
//   max-width: 100%;
// }
.h-100 {
  max-height: 100%;
}
.fs-d8px {
  font-size: 8px;
}
.fs-d12px {
  font-size: 12px;
}
.fs-d12px-m10px {
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 12px;
  }
}
.fs-d14px {
  font-size: 14px;
}
.fs-d14px-m10px {
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}
.fs-d14px-m12px {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}
.fs-d16px-m14px {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  font-size: 14px;
}
.fs-d18px {
  font-size: 18px;
}
.fs-d20px {
  font-size: 20px;
}
.fs-d20px-md14px {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
}
.fs-d24px {
  font-size: 24px !important;
}
.fs-d24px-m14px {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}
.fs-d24px-m20px {
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}
.fs-d26px {
  font-size: 26px;
}
.fs-d28px {
  font-size: 28px;
}
.fs-d30px {
  font-size: 30px;
}
.fs-d30px-m18px {
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 30px;
  }
}
.fs-d34px {
  font-size: 34px;
}
.fs-d34px-m24px {
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 34px;
  }
}
.fs-d35px {
  font-size: 35px;
}

.text-ls-green {
  color: #128e6d;
}
.text-ls-yellow {
  color: #eda400;
}
.text-ls-red {
  color: #ce2c1e;
}
.text-ls-gray {
  color: #a8a8a8;
}
.text-global-red {
  color: red;
}

// .text-start {
//   text-align: start;
// }

.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}

.p-0 {
  padding: 0 !important;
}
.pt-5px {
  padding-top: 5px;
}
.pb-15px {
  padding-bottom: 15px;
}
.m-0 {
  margin: 0px !important;
}
.mt-10px {
  margin-top: 10px;
}
.mt-50px {
  margin-top: 20px !important;
  @media (min-width: 1024px) {
    margin-top: 50px !important;
  }
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-14px {
  margin-bottom: 14px;
}
.mb-15px {
  margin-bottom: 15px;
}
.mt-20px {
  margin-top: 20px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-10px-m-0 {
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-35px {
  margin-bottom: 35px;
}
.mb-30px {
  margin-bottom: 30px;
}
.mb-30px-m-0 {
  margin-bottom: 0;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
}
.mb-40px {
  margin-bottom: 40px;
}
.mb-50px {
  margin-bottom: 50px;
}
.mb-60px {
  margin-bottom: 60px;
}
.mb-73px {
  margin-bottom: 73px;
}
.mb-80px {
  margin-bottom: 80px;
}
.mb-100px {
  margin-bottom: 100px;
}
.mb-180px {
  margin-bottom: 180px;
}
.ms-5px {
  margin-left: 5px;
}
.ms-10px {
  margin-left: 10px;
}
.me-5px {
  margin-right: 5px;
}
.me-6px {
  margin-right: 6px;
}
.me-10px {
  margin-right: 10px;
}
.me-25px {
  margin-right: 25px;
}
.me-35px {
  margin-right: 35px;
}
.my-15px {
  margin: 15px 0;
}
.my-42px {
  margin: 42px 0;
}

// .w-100 {
//   width: 100%;
// }

.btn-line {
  background-color: #5ac463;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
  // height: 60px;
  &:hover {
    background-color: #50a457;
  }
}
.btn-login {
  background: transparent linear-gradient(90deg, #db2718 0%, #aa2217 100%) 0% 0%
    no-repeat padding-box;
  cursor: pointer;
  height: 50px;
  border-radius: 10px;
  padding: 15px;
  &.disabled {
    background: #dedede;
  }
}
.text-decoration-none {
  text-decoration: none !important;
}
.required {
  &:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: #aa2217;
    padding-left: 0.25rem;
  }
}
// .text-danger {
//   color: #aa2217;
// }

.autocomplete-suggestion {
  cursor: pointer;
  padding: 0.75rem 1.25rem; /* Unified padding */
  color: rgb(0, 0, 0);
  transition: color 0.2s ease, background-color 0.2s ease; /* Added transition for background color */
  margin: 0; /* Unified margin */
  border-bottom: none !important; /* Avoid interfering with other styles */
}

.auto-complete-list {
  max-height: 200px;
  overflow: auto; /* Changed to 'auto' for better UX */
  z-index: 1051; /* Higher than .fancybox__content */
  position: absolute;
  background-color: #f8f8f8;
  border-radius: 0.475rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border: 0;
  padding: 1rem 0;
}

.auto-complete-list .list-team:hover {
  display: flex;
  background-color: #efefef;
  color: #0000008d;
  position: relative;
  z-index: 1051;
}

.team-logo {
  max-width: 30px;
  margin-right: 5px;
}

// .d-flex {
//   display: flex;
// }

// .justify-content-center {
//   justify-content: center !important;
// }
// .justify-content-between {
//   justify-content: space-between !important;
// }
// .justify-content-evenly {
//   justify-content: space-evenly !important;
// }
// .align-items-center {
//   align-items: center;
// }
// .position-reletive {
//   position: relative;
// }

.main-ls-header {
  background: transparent linear-gradient(270deg, #db2718 0%, #981e15 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 56px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    margin-top: 46px;
    margin-bottom: 70px;
  }
  .btn-return-home {
    position: absolute;
    top: 9;
    left: 12px;
    align-items: center;
    column-gap: 0.5rem;
    display: inline-flex;
    border-radius: 16px;
    padding: 4px 7px;
    @media (min-width: 768px) {
      top: 11px;
      background: #00000065 0% 0% no-repeat padding-box;
    }
  }
  .circle-back {
    background: #aa2217 0% 0% no-repeat padding-box;
    padding: 8px 9px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 24px;
  }
}
// .d-none {
//   display: none;
// }
// .d-block {
//   display: block !important;
// }
.hashtag {
  display: flex;
  align-items: center;
}

.delete-button {
  margin-left: 10px;
  border-radius: 24px;
  background: linear-gradient(
    90deg,
    rgb(206, 14, 45) 0%,
    rgb(223, 43, 65) 100%
  );
  color: white;
  border: none;
  padding: 5px 6px;
  cursor: pointer;
  font-size: 7px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.form-select-teams {
  padding: 50px !important;
}
.select2-result-repository {
  display: flex;
  align-items: center;
}

.select2-result-repository__avatar {
  flex: 0 0 auto;
  padding-right: 10px;
}

.select2-result-repository__meta {
  flex: 1 1 auto;
}
.custom-form-css {
  .select2-container .select2-selection--single .select2-selection__rendered {
    // width: 100%;
    padding: 12px 20px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .select2-container--default .select2-selection--single {
    background-color: none;
    border: none;
    border-radius: none;
  }
}
span.select2-container--open .select2-dropdown {
  top: 24px !important;
}
.select2-dropdown {
  border: 1px solid #ccc !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #ccc !important;
  border-radius: 4px;
}
.select2-search--dropdown {
  padding: 16px 16px 4px 16px;
}
.select2-results__option {
  padding: 4px 16px 16px 16px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 50%;
}
.tooltip-ls {
  position: relative;
  display: inline-block;
  z-index: 9999;
}
.tooltip-ls:hover {
  img {
    content: url(/frontend/img/icon_tooltips_open.png);
    width: 16px;
  }
}
.tooltip-ls .tooltiptext {
  visibility: hidden;
  background: rgba(#000000, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 12px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  max-width: 110px;
  @media (min-width: 1024px) {
    max-width: 165px;
  }
}

.tooltip-ls:hover .tooltiptext {
  visibility: visible;
}
.tooltip-ls .tooltiptext {
  width: 110px;

  bottom: 100%;
  left: 50%;
  margin-left: -55px; /* Use half of the width (120/2 = 60), to center the tooltip */
  @media (min-width: 1024px) {
    width: 165px;
    margin-left: -82.5px;
  }
}

.tooltip-ls .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(#000000, 0.7) transparent transparent transparent;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px !important;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px !important;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px !important;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px !important;
  }
}

.container {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  width: 1160px !important;
}

// .container-custom {
//   max-width: 100%;
//   padding-left: 1rem;
//   padding-right: 1rem;
//   position: relative;
//   width: 1160px !important;
//   margin-left: auto;
//   margin-right: auto;
//   @media (min-width: 640px) {
//     max-width: 640px !important;
//   }
//   @media (min-width: 768px) {
//     max-width: 768px !important;
//   }
//   @media (min-width: 1024px) {
//     max-width: 1024px !important;
//   }
//   @media (min-width: 1280px) {
//     max-width: 1280px !important;
//   }
//   @media (min-width: 1536px) {
//     max-width: 1536px !important;
//   }
// }
#footer {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 251 / var(--tw-bg-opacity));
  border-color: rgb(215 218 226 / var(--tw-border-opacity));
  border-top-width: 1px;
  margin-top: 3rem;
  padding-bottom: 4rem;
  position: relative;
  text-align: center;
  @media (min-width: 1280px) {
    margin-top: 5rem;
  }
  .section-top {
    text-align: center;
    .logo {
      --tw-bg-opacity: 1;
      align-items: center;
      background-color: rgb(170 34 23 / var(--tw-bg-opacity));
      border-radius: 22px;
      display: inline-flex;
      height: 2rem;
      justify-content: center;
      padding-left: 18px;
      padding-right: 18px;
      position: relative;
      top: -1rem;
    }
    .menu-footer {
      h2 {
        --tw-text-opacity: 1;
        color: rgb(170 34 23 / var(--tw-text-opacity));
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      nav {
        display: grid;
        font-size: 0.875rem;
        font-weight: 500;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        line-height: 1.25rem;
        margin-top: 0.625rem;
        white-space: nowrap;
        @media (min-width: 640px) {
          font-size: 0.75rem;
          line-height: 1rem;
        }
        @media (min-width: 768px) {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
        @media (min-width: 1280px) {
          margin-top: 0.75rem;
        }
        ul {
          display: grid;
          row-gap: 0.25rem;
          list-style: none;
          margin: 0;
          padding: 0;
          @media (min-width: 640px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          @media (min-width: 1024px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            row-gap: 0.5rem;
          }
          a {
            color: inherit;
            text-decoration: inherit;
          }
        }
      }
    }
  }
  .section-bottom {
    font-size: 0.75rem;
    line-height: 1rem;
    .social {
      align-items: center;
      -moz-column-gap: 0.75rem;
      column-gap: 0.75rem;
      display: flex;
      width: 100%;
      .group-btn {
        align-items: center;
        -moz-column-gap: 0.5rem;
        column-gap: 0.5rem;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
  .section-bottom > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    @media (min-width: 1280px) {
      --tw-space-y-reverse: 0;
      margin-bottom: calc(1rem * var(--tw-space-y-reverse));
      margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    }
  }
  .container > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(18px * var(--tw-space-y-reverse));
    margin-top: calc(18px * (1 - var(--tw-space-y-reverse)));
  }
  @media (min-width: 1280px) {
    .container > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
      margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    }
  }
}
.btn-to-top {
  --tw-bg-opacity: 1;
  align-items: center;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 55px;
  padding: 0.25rem;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  .icon {
    --tw-bg-opacity: 1;
    align-items: center;
    background-color: rgb(170 34 23 / var(--tw-bg-opacity));
    border-radius: 9999px;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;
  }
  .text {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.25rem;
  }
}
.-top-6 {
  top: -1.5rem !important;
}
.right-2 {
  right: 0.5rem !important;
}
@media (min-width: 1280px) {
  .xl\:right-\[14px\] {
    right: 14px !important;
  }
}
@media (min-width: 1280px) {
  .xl\:-top-7 {
    top: -1.75rem !important;
  }
}
.absolute {
  position: absolute !important;
}
.jodit-status-bar {
  display: none !important;
  @media (min-width: 768px) {
    display: block !important;
  }
}
.modal-custom-bottom {
  .modal-dialog-centered {
    // @media (min-width: 768px) {
    align-items: end;
    // }
  }
  .modal-dialog {
    // @include media-breakpoint-down(sm) {
    min-height: calc(100% - 53px);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 53px;
    // }
  }
  .modal-body {
    padding: 30px 45px;
  }
  .modal-content {
    border-radius: 30px 30px 0 0;
    border: none;
  }
  .form-control {
    padding: 12px 20px 12px 45px;
  }
}
.btn-sort {
  background: #ebecf0 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 0;
  img {
    width: 20px;
    height: 20px;
  }
}

.page-style {
  .page-link {
    padding: 10px !important;
    color: rgb(255, 255, 255);
    background: rgb(170, 34, 23);
    border-radius: 5px;
    width: 44px;
    height: 44px;
    text-align: center;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      color: rgb(170, 34, 23);
    }
  }
  .page-item.active {
    .page-link {
      padding: 10px !important;
      background: none;
      color: rgb(170, 34, 23);
      border-radius: 5px;
      border: 1px solid #c0c0c0c0;
      width: 44px;
      height: 44px;
      text-align: center;
      font-size: 16px;
      margin-right: 10px;
      &:hover {
        color: rgb(170, 34, 23);
      }
    }
  }

  a {
    text-decoration: none;
    color: #fff;
  }
}

.wh-emoji-100px {
  width: 100px !important;
  height: 100px !important;
  display: inline-block !important;
}

.fav-box {
  .icon-fav {
    content: url(/frontend/img/icon_heart_outline.svg);
    width: 15px;
  }
  &.active {
    .icon-fav {
      content: url(/frontend/img/icon_heart_fill.svg);
    }
  }
}

.btn-global-submit {
  background: transparent linear-gradient(90deg, #aa2217 0%, #db2718 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 23px;
  padding: 12px 46px;
  color: #fff;
}

// .list-number {
//   ol {
//     list-style-type: decimal !important;
//     line-height: 2;
//   }
// }

.h1-style-1 {
  font-size: 24px !important;
  font-weight: inherit;
}
.h1-style-2 {
  font-size: 18px !important;
  font-weight: inherit;
  @media (min-width: 768px) {
    font-size: 30px !important;
  }
}
.h1-style-3 {
  font-size: 24px !important;
  font-weight: inherit;
  @media (min-width: 768px) {
    font-size: 34px !important;
  }
}
.h2-style-1 {
  font-size: 18px !important;
  font-weight: inherit;
}
.h2-style-2 {
  font-size: 12px !important;
  font-weight: inherit;
}
.h2-style-3 {
  font-size: 14px !important;
  font-weight: inherit;
}
.h2-style-4 {
  font-size: 20px !important;
  font-weight: inherit;
  @media (min-width: 768px) {
    font-size: 24px !important;
  }
}
.h2-style-5 {
  font-size: 16px !important;
  font-weight: inherit;
}
.h2-style-6 {
  font-size: 14px !important;
  font-weight: inherit;
  @media (min-width: 768px) {
    font-size: 24px !important;
  }
}
.h3-style-1 {
  font-size: 12px !important;
  font-weight: inherit;
}
.h3-style-2 {
  font-size: 26px !important;
  font-weight: inherit;
}
.h3-style-3 {
  font-size: 12px !important;
  font-weight: inherit;
  @media (min-width: 768px) {
    font-size: 14px !important;
  }
}

.fs-inherit {
  font-size: inherit;
}

.mini-element {
  .football-results-page {
    @media (min-width: 768px) {
      .table-match .title-bar .inner {
        padding-top: 3px;
        footer {
          margin-top: 0;
        }
      }
    }
    @media (max-width: 1023.99px) {
      .table-match .title-bar .inner {
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: inherit !important;
        padding-top: 3px;
      }
      footer {
        // border: solid 1px blue;
        height: inherit !important;
        margin-top: 0 !important;
        .btn-live {
          display: block !important;
          height: inherit;
          padding: 0;
        }
      }
    }
  }
}

.modal-profile {
  @media (max-width: 699.99px) {
    padding-bottom: 105px !important;
  }
}