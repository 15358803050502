.modal-global {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  padding: 40px !important;
  @media (min-width:768px) {
  padding: 46px 85px !important;
    
  }
  .f-button {
    display: none;
  }
  .profile_preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: -webkit-center;
    align-items: center;
  }
  input.form-control {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    @media (max-width: 699.99px) {
      padding: 10px;
    }
  }
  .form-select {
    padding: 12px 20px;
    margin: 8px 0;
    display: block;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #c0c2d0;
  }
  .label-for-check {
    border: 1px solid #dcdeed;
    color: #dcdeed;
    border-radius: 5px;
    padding: 15px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    @media (max-width: 699.99px) {
      padding: 10px;
    }
  }
  .check-with-label:checked + .label-for-check {
    border: 1px solid #000000;
    color: #000000;
  }
  .d-none {
    display: none;
  }
  .btn-ls-cancle {
    border: 1px solid #aa2217;
    border-radius: 23px;
    padding: 12px 46px;
    color: #aa2217;
  }
  .btn-ls-submit {
    background: transparent linear-gradient(90deg, #aa2217 0%, #db2718 100%) 0%
      0% no-repeat padding-box;
    border-radius: 23px;
    padding: 12px 46px;
    color: #fff;
  }
  .profile_preview {
    position: relative;
    .profile_edit {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #aa2217;
      box-shadow: 3px 5px 8px #5c00004d;
      width: 30px;
      border-radius: 30px;
      padding: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      img {
        width: 13px;
      }
    }
    .profile_preview_outer {
      position: relative;
      width: 150px;
      height: 150px;
      Ï &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(1 / 1) * 100%;
      }
      > .profile_preview_inner {
        background-color: #CCCCCC;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-position: center;
        background-size: cover;
        // transform: translateX(-50%) translateY(-50%);
      }
    }
  }
  .banner_preview {
    position: relative;
    .banner_edit {
      position: absolute;
      bottom: -10px;
      right: -10px;
      background-color: #aa2217;
      box-shadow: 3px 5px 8px #5c00004d;
      width: 30px;
      border-radius: 30px;
      padding: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      img {
        width: 13px;
      }
    }
  }
  .thumb-outer {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    @media (min-width: 991px) {
      border-radius: 10px;
    }
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(320 / 1389) * 100%;
    }
    > .thumb-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: #CCCCCC;

      background-size: cover;
      background-position: center;
    }
  }
}
