.vote-main {
  // background-color: red;
  .header-ls-vote {
    margin-bottom: 1.75rem;
  }
  // .table-match {
  //   padding: 45px 56px;
  //   border-radius: 30px;ƒ
  //   box-shadow: 0px 10px 25px #a1a3b233;
  // }
  .badge-ranking {
    background: transparent linear-gradient(90deg, #000000 0%, #aa2217 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000026;
    border-radius: 0px 30px 30px 0px;
    color: #fff;
    font-size: 20px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
    font-weight: 600;
    padding: 13px 30px;
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 30px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .top-ranking {
    // display: flex;
    // align-items: baseline;
    // justify-content: space-between;
    margin-bottom: 40px;
    .ranking-frame {
      flex: 0 0 auto;
      // .bagde{
      // margin: 0 -37.5px;
      // }badge-rank
      .profile_frame {
        margin: 0 auto;
        width: 100px;
        height: 100px;
        background-position: center 0;
        background-size: contain;
        position: relative;
        background-repeat: no-repeat;
        @media (min-width: 768px) {
          width: 220px;
          height: 237px;
        }
        // margin: 0 auto;
        .badge-rank {
          position: absolute;
          top: 0;
          right: 5px;
          img {
            width: 22px;
            @media (min-width: 768px) {
              width: 56px;
            }
          }
          &.top {
            img {
              width: 30px;
              @media (min-width: 768px) {
                width: 60px;
              }
            }
          }
        }
        &.top-1 {
          width: 100px;
          height: 129px;
          @media (min-width: 768px) {
            width: 250px;
            height: 269px;
          }
          .profile_inner {
            top: 25px;
            bottom: 50px;
            left: 0px;
            right: 0px;
            @media (min-width: 768px) {
              top: 20px;
              left: 74px;
              right: 74px;
            }
            .profile_picture {
              .profile_picture_outer {
                width: 40px;
                height: 40px;
                @media (min-width: 768px) {
                  width: 100px;
                  height: 100px;
                }
              }
            }
          }
          &.hall-profile {
              width: 250px;
              height: 269px;
            .profile_type {
              display: block;
            }
            .profile-name {
              font-size: 16px;
            }
            .profile_inner {
              top: 20px;
              bottom: 50px;
              left: 74px;
              right: 74px;
              .profile_picture {
                .profile_picture_outer {
                  width: 100px;
                  height: 100px;
                }
              }
            }
          }
        }
        .profile_inner {
          position: absolute;
          top: 20px;
          left: 0px;
          right: 0px;
          bottom: 48px;
          @media (min-width: 768px) {
            top: 17px;
            left: 68px;
            right: 68px;
          }
          // border: red 1px solid;
          text-align: -webkit-center;
          .profile_type {
            display: none;
            margin-bottom: 8px;
            img {
              width: 15px;
            }
            @media (min-width: 768px) {
              display: block;
            }
          }
          .profile-name {
            font-size: 8px;
            @media (min-width: 768px) {
              font-size: 16px;
            }
            color: #fff;
            text-shadow: 0px 3px 6px #00000034;
          }
          .profile_picture {
            // margin-bottom: 5px;

            .profile_picture_outer {
              position: relative;
              width: 37.6px;
              height: 37.6px;
              @media (min-width: 768px) {
                width: 82px;
                height: 82px;
              }
              Ï &:before {
                display: block;
                content: "";
                width: 100%;
                padding-top: calc(1 / 1) * 100%;
              }
              > .profile_picture_inner {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: url(/frontend/img/pic_defaultprofile@2x.png);

                background-color: #ffffff;

                background-position: center;
                background-size: cover;
                // transform: translateX(-50%) translateY(-50%);
              }
            }
          }
          .team-logo {
            img {
              width: 20px;
              @media (min-width: 768px) {
                width: 28px;
              }
            }
          }
        }
      }
    }
    .score-top-vote {
      margin-top: 5px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 9.5px 14.5px;
      // width: fit-content;
    }
  }
  .table-score {
    background-image: url(/frontend/img/bg_votingboard@2x.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 21.5px 15px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 35px;
    // display: flex;
    justify-content: space-between;
    @media (min-width: 768px) {
      padding: 21.5px 47.49px;
    }
    &.rank {
      display: block;
      table {
        tr:nth-child(even) {
          background-color: #fff;
        }
      }
      .bg-table-w {
        background: none;
      }
    }
    @media (max-width: 767px) {
      .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
      }
      .table-responsive > .table {
        width: 100%;
      }
      .table-responsive > .table > thead > tr > th,
      .table-responsive > .table > tbody > tr > td {
        white-space: nowrap; /* ยังไงก็ตาม */
      }
    }
    .table-spacing {
      border-spacing: 0 5px;
    }
    table {
      border-collapse: separate;
      border-spacing: 0;
      // flex: 0 0 49%;
      width: 100%;
      --bs-table-striped-bg: none;
      td {
        width: fit-content;
      }
    }
    .table > :not(caption) > * > * {
      padding: 16px 40px;
      @media (min-width: 768px) {
        padding: 5px 10px;
      }
      @media (min-width: 991px) {
        padding: 16px 40px;
      }
      color: var(
        --bs-table-color-state,
        var(--bs-table-color-type, var(--bs-table-color))
      );
      border-bottom-width: 0;
      box-shadow: inset 0 0 0 9999px
        var(
          --bs-table-bg-state,
          var(--bs-table-bg-type, var(--bs-table-accent-bg))
        );
    }
    td {
      padding: 6px 6px;
      font-size: 12px;
      background: transparent;
      @media (min-width: 768px) {
        padding: 6px 28px;

        font-size: 16px;
      }
    }
    // tr {
    //   background: #ffffff 0% 0% no-repeat padding-box;
    //   border-radius: 20px;

    // }

    tr.title-table {
      background: none;
    }
    .bg-table-w {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 20px;
      margin-bottom: 6px;
      .name {
        margin-left: 5px;
      }
      &.me {
        background: #6b160f;
        color: #fff;
        td {
          color: #fff;
        }
      }
      &.me-vote {
        background: #6b160f;
        color: #fff;
        td {
          color: #fff;
        }
      }

      // fix for ios safari
      @media (max-width: 990px) {
        &.me {
          transform: scale(1);
          background: #6b160f;

          color: #fff;
          position: relative;
          td {
            color: #fff;
            &:first-child::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              // height: 42px;

              background: #6b160f;
              z-index: -1;
              border-radius: 50px;
              // bottom: 0;
              // background: red;
            }
          }
        }
      }
      @media (max-width: 767px) {
        &.me {
          transform: scale(1);
          background: #6b160f;

          color: #fff;
          position: relative;
          td {
            color: #fff;

            &:first-child::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              // height: 70px;

              background: #6b160f;
              z-index: -1;
              border-radius: 50px;
              // bottom: 0;
              // background: red;
            }
          }
        }
      }
      @media (max-width: 500px) {
        &.me {
          transform: scale(1);
          background: #6b160f;

          color: #fff;
          position: relative;
          td {
            color: #fff;

            &:first-child::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              // height: 44px;

              background: #6b160f;
              z-index: -1;
              border-radius: 50px;
              // bottom: 0;
              // background: red;
            }
          }
        }
      }
      @media (max-width: 990px) {
        &.me-vote {
          transform: scale(1);
          background: #6b160f;

          color: #fff;
          position: relative;
          td {
            color: #fff;
            &:first-child::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              // height: 44px;

              background: #6b160f;
              z-index: -1;
              border-radius: 50px;
              // bottom: 0;
              // background: red;
            }
          }
        }
      }
      @media (max-width: 767px) {
        &.me-vote {
          transform: scale(1);
          background: #6b160f;

          color: #fff;
          position: relative;
          td {
            color: #fff;

            &:first-child::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              // height: 44px;

              background: #6b160f;
              z-index: -1;
              border-radius: 50px;
              // bottom: 0;
              // background: red;
            }
          }
        }
      }
      // END fix for ios safari
    }
    tr {
      margin-bottom: 6px;
    }
    .border-l {
      border-radius: 20px 0 0 20px;
    }
    .border-r {
      border-radius: 0 20px 20px 0;
    }
    .profile_frame {
      // margin: 0 37.5px;

      width: 30px;
      height: 25px;
      background-position: center 0;
      background-size: contain;
      position: relative;
      background-repeat: no-repeat;
      // margin: 0 auto;
      .profile_inner {
        position: absolute;
        top: 7.5px;
        left: 7.5px;
        right: 7.5px;
        bottom: 7.7px;
        text-align: -webkit-center;
        .profile_type {
          img {
            width: 15px;
          }
        }
        .profile-name {
          font-size: 2px;

          color: #fff;
          text-shadow: 0px 3px 6px #00000034;
        }
        .profile_picture {
          // margin-bottom: 5px;

          .profile_picture_outer {
            position: relative;
            width: 12px;
            height: 12px;
            Ï &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: calc(1 / 1) * 100%;
            }
            > .profile_picture_inner {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-image: url(/frontend/img/pic_defaultprofile@2x.png);

              background-color: #ffffff;

              background-position: center;
              background-size: cover;
              // transform: translateX(-50%) translateY(-50%);
            }
          }
        }
        .team-logo {
          img {
            width: 3px;
          }
        }
      }
    }
  }
  .table-match {
    // box-shadow: 0px 10px 25px #A1A3B233;
    border-radius: 30px;
    --tw-shadow: 0;
    // .table-match{
    .row {
      width: fit-content;
      @media (min-width: 768px) {
        width: 100%;
      }
      .btn-match-info {
        margin-right: 20px;
        order: -1;
        @media (min-width: 640px) {
          margin-right: 0;
          order: 0;
        }
      }
    }
    // }
  }
  // .football-results-page .table-match .row{
  // }

  .box-match {
    .row > * {
      flex-shrink: inherit;
      width: 100%;
      max-width: 100%;
      padding-right: inherit;
      padding-left: inherit;
      margin-top: inherit;
    }
  }
}

.text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.badge-name-rank {
  padding: 7px;
  background: transparent linear-gradient(270deg, #a92218 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  font-size: 10px;
  color: #fff;
  border-radius: 20px;
  @media (min-width:768px) {
  font-size: 12px;
    
  }
}

.box-ranking {
  position: relative; 
   img {
    width: 300px; 
    height: 44px;
  }
  .season-text {
    position: absolute;
    top: 55%; 
    left: 44%; 
    transform: translate(-37%, -60%); 
    color: #FFFFFF;
    @media (max-width:767.98px) {
      font-size: 16px;
    }
  }
}

.season-month-text {
  font-size: 24px;
  @media (max-width:767.98px) {
    font-size: 16px !important;
  }
}






.bg-month{
  padding: 3px 10px 5px 10px;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 150px;
}

.bg-ranking{
  padding: 30px 0 30px 0;
  background: transparent linear-gradient(0deg, #F8F1D7 0%, #FCFAF1 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  position: relative;
  .bg-ranking-name {
   padding: 9px 57px 10px 55px;
   line-height: 1.4;
   background: rgb(242,174,72);
    background: linear-gradient(90deg, rgba(242,174,72,1) 0%, rgba(248,220,122,1) 25%, rgba(245,204,71,1) 50%, rgba(236,182,89,1) 77%, rgba(242,166,60,1) 100%);
    border: 3px solid #FFFFFF5A;
   width: 250px;
   height: 50px;
   border-radius: 30px;
   opacity: 1;
   position: absolute;
   bottom: -6%; 
   left: 50%;
   transform: translateX(-50%);
   z-index: 3;
  }
}

.bg-ranking-2{
  padding: 30px 0 30px 0;
  background:  transparent linear-gradient(0deg, #F1EFE9 0%, #FCFAF4 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  position: relative;
  .bg-ranking2-name {
   padding: 9px 57px 10px 55px;
   line-height: 1.4;
   width: 250px;
   height: 50px;
   background: rgb(154,154,154);
   background: linear-gradient(90deg, rgba(154,154,154,1) 0%, rgba(169,169,169,1) 25%, rgba(201,201,203,1) 50%, rgba(221,221,221,1) 77%, rgba(174,174,174,1) 100%);
   border: 3px solid #FFFFFF5A;
   border-radius: 30px;
   opacity: 1;
   position: absolute;
   bottom: -6%; 
   left: 50%;
   transform: translateX(-50%);
   z-index: 3;
  }
}

.bg-voiting{
 
  background: transparent linear-gradient(270deg, #AA2217 0%, #000000 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.bg-white-month{
 padding: 10px 20px 10px 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
 border-radius: 20px;
}

.text-black {
  color: #000000;
}
.text-white {
  color: #ffffff;
}
.text-green {
  color: #2EAC77;
}

.month-text{
  background: transparent linear-gradient(270deg, #AA2217 0%, #000000 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  font-size: 16px;
  font-weight: 600;
  // line-height: 1;
  // padding: 5px 0;
  text-align: center;
  @media (max-width: 767.98px) {
    font-size: 12px;
  }
}
// .text-vote {
  
  
// }

.box-result{
  padding: 30px 30px 30px 30px;
  background: transparent linear-gradient(180deg, #E9EDF1 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #A1A3B233;
  border-radius: 30px; 
}



// .block-grey-a {
//   background: rgb(154,154,154);
//   background: linear-gradient(90deg, rgba(154,154,154,1) 0%, rgba(169,169,169,1) 25%, rgba(201,201,203,1) 50%, rgba(221,221,221,1) 77%, rgba(174,174,174,1) 100%);
//   padding: 3px;
//   border-radius: 10px;
//   overflow: hidden;
//   position: relative;
// }
// .block-grey-b {
//   background: rgb(131,58,180);
//   background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
//   border-radius: 10px;
//   overflow: hidden;
//   position: relative;
// }

.table-score {
  padding: 30px 30px 30px 30px;
  background: transparent linear-gradient(180deg, #E9EDF1 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 10px 25px #A1A3B233;
  border-radius: 30px;

  .table-spacing-vote {
    border-spacing: 10px   !important;
    thead {
      position: relative;
      overflow: hidden;
      border-radius: 40px;
    }
    span {
      position: relative;
      z-index: 2;
    }
    th {
      @media (max-width: 767.98px) {
        font-size: 12px;
      }
      padding: 10px !important;
      background: none !important;
      span {
        color: #FFFFFF;
      }
      &::after {
        content: '';
        display: block;
        background: #6b160f;
        position: absolute;
        top: 0;
        left: -10px;
        right: -10px;
        bottom: 0;
      }
    }
    th.th-1 {
      position: relative;
      width: 20%;
      &::after {
        border-radius: 40px 0 0 40px;
        overflow: hidden;
      }
    }
    th.th-2 {
      position: relative;
      width: 25%;
    }
    th.th-3 {
      position: relative;
      width: 15%;
      text-align: center;
    }
    th.th-4 {
      position: relative;
      width: 25%;
    }
    th.th-5 {
      position: relative;
      width: 15%;
      text-align: center;
      &::after {
        border-radius: 0 40px 40px 0;
        overflow: hidden;
      }
    }
    span {
      position: relative;
      z-index: 2;
    }
    td.td-1 {
      position: relative;
      &::after {
        content: '';
        display: block;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 20px ;
        z-index: 1;
      }
    }
    td.td-2 {
      position: relative;
      &::after {
        content: '';
        display: block;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        right: -10px;
        top: 0;
        bottom: 0;
        border-radius: 20px 0 0 20px;
        z-index: 1;
      }
    }
    td.td-3 {
      position: relative;
      &::after {
        content: '';
        display: block;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0 20px 20px 0;
        z-index: 1;
      }
    }
    td.td-4 {
      position: relative;
      &::after {
        content: '';
        display: block;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        right: -10px;
        top: 0;
        bottom: 0;
        border-radius: 20px 0 0 20px;
        z-index: 1;
      }
    }
    td.td-5 {
      position: relative;
      &::after {
        content: '';
        display: block;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0 20px 20px 0;
        z-index: 1;
      }
    }

    th, td {
      padding: 6px 25px !important;
    }
    @media (max-width: 767.98px) {
      th,td {
        padding: 5px 25px !important;
        vertical-align: middle;
      }
    }

  }
  
}


.add-hall-of-frame {
  position: relative;
  &::after {
    pointer-events: none;

    display: block;
    content: '';
    position: absolute;
    width: 70%;
    left: 50%;
    top: -11%;
    height: 50%;
    z-index: 2;

    transform: translateX(-50%);
  
    background-image: url("/frontend/img/badge_silver@2x.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  &.silver {
    &::after {
      background-image: url("/frontend/img/badge_silver@2x.png");
    }
  }
  &.gold {
    &::after {
      background-image: url("/frontend/img/badge_gold@2x.png");
    }
  }
}
