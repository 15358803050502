.bg-profile {
  background: url(/frontend/img/bg_profile@2x.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: calc(100vh - 270px);
  padding-top: 70px;
  @media (min-width: 991px) {
    padding-top: 120px;
  }
  .box-history {
    // .nav-tabs{
    //   --bs-nav-tabs-link-active-color:#fff !important;
    // }
    // .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    //   color: #fff !important;
    // }
    .btn-load {
      background: transparent linear-gradient(90deg, #aa2217 0%, #db2718 100%)
        0% 0% no-repeat padding-box;
      border-radius: 23px;
      padding: 12px;
      color: #fff;
      cursor: pointer;
    }
    .nav-tabs {
      border-bottom: 0 !important;
    }
    .nav-item {
      height: 100%;
    }
    .badge-profile-tab {
      background: transparent linear-gradient(180deg, #fcfcfc 0%, #e8e8e8 100%)
        0% 0% no-repeat padding-box;
      border: 1px solid #ededed;
      border-radius: 10px;
      color: #000000;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 15px;
      }
      &.active {
        background: transparent
          linear-gradient(180deg, #d82719 0%, #961e13 100%) 0% 0% no-repeat
          padding-box;
        color: #fff !important;
        .text {
          color: #fff;
        }
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .wrapper-table-profile {
    .table > :not(caption) > * > * {
      padding: 16px 40px;
      color: var(
        --bs-table-color-state,
        var(--bs-table-color-type, var(--bs-table-color))
      );
      background-color: var(--bs-table-bg);
      border-bottom-width: 0;
      box-shadow: inset 0 0 0 9999px
        var(
          --bs-table-bg-state,
          var(--bs-table-bg-type, var(--bs-table-accent-bg))
        );
      border-radius: 20px;
    }
    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .date {
      img {
        width: 9px;
      }
    }
  }
}

.cover_edit {
  position: absolute;
  top: 10px;
  right: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  padding: 6px 10px;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  font-size: 12px;
  img {
    margin-left: 5px;
  }
  .cover_edit-text {
    display: inline-block;
    img {
      display: inline-block;
    }
  }
}

.cover-profile {
  position: relative;

  @media (min-width: 991px) {
  }
  .thumb-outer {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    @media (min-width: 991px) {
      border-radius: 30px;
    }
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(320 / 1389) * 100%;
    }
    > .thumb-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #cccccc;
      background-position: center;
      background-size: cover;
    }
  }
}
.profile_info {
  margin-top: 20px;
  @media (min-width: 991px) {
    margin-top: 55px;
  }
  .profile_frame {
    width: 360px;
    height: 360px;
    @media (max-width: 540px) {
      width: 300px;
      height: 300px;
    }
    background-position: center center;
    background-size: contain;
    position: relative;

    margin: 0 auto;
    @media (min-width: 991px) {
      margin: 0;
    }
    .profile_edit {
      position: absolute;
      top: 8%;
      right: 17%;
      background-color: #aa2217;
      box-shadow: 3px 5px 8px #5c00004d;
      width: 30px;
      border-radius: 30px;
      padding: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 13px;
      }
    }
    .profile_inner {
      position: absolute;
      top: 30px;
      left: 90px;
      right: 90px;
      bottom: 72px;
      // border: red 1px solid;
      text-align: -webkit-center;
      .profile_type {
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 12px;
        padding: 3px 14px;
        width: fit-content;
        text-align: center;
        margin-bottom: 9px;
      }
      .profile-name {
        font-size: 24px;
        margin-bottom: 0px;
        font-weight: inherit;
      }
      .profile_picture {
        margin-bottom: 5px;

        .profile_picture_outer {
          position: relative;
          width: 150px;
          height: 150px;
          @media (max-width: 540px) {
            width: 100px;
            height: 100px;
          }
          Ï &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: calc(1 / 1) * 100%;
          }
          > .profile_picture_inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url(/frontend/img/pic_defaultprofile@2x.png);
            background-color: #ffffff;

            background-position: center;
            background-size: cover;
            // transform: translateX(-50%) translateY(-50%);
          }
        }
      }
    }
  }
  .locker_default {
    position: relative;
    .icon-edit-item,
    .icon-edit {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #999999;

      color: #ffffff;
      border-radius: 50px;
      height: 50px;
      width: 50px;
      overflow: hidden;
      font-size: 44px;

      display: flex;
      align-items: center;
      justify-content: center;

      z-index: 10;
      padding: 18px;
      cursor: pointer;
      img {
        width: 17px;
      }
    }
    .icon-edit-item {
      display: none;
    }

    .pos-number {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
    }
    .pos {
      position: absolute;
      // border: solid 1px red;
      text-align: center;
      .tooltip-description {
        min-width: 130px;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 10;
        background: rgba(#000000, 0.7);
        padding: 10px;
        border-radius: 10px;
        transform: translateX(-50%);
        color: #ffffff;
        width: 90%;
        font-size: 12px;

        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        line-break: anywhere;
      }
      &:hover {
        .tooltip-description {
          opacity: 1;
          visibility: visible;
        }
      }
      img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
      }
      &.pos-1 {
        top: 11px;
        left: 22px;
        @media (max-width: 540px) {
          left: 16px;
        }
      }
      &.pos-2 {
        top: 11px;
        left: 98px;
        @media (max-width: 540px) {
          left: 68px;
        }
      }
      &.pos-3 {
        top: 11px;
        left: 175px;
        @media (max-width: 540px) {
          left: 120px;
        }
      }
      &.pos-1,
      &.pos-2,
      &.pos-3 {
        width: 65px;
        height: 147px;

        @media (max-width: 540px) {
          height: 103px;
          width: 50px;
        }

        display: flex;
        align-items: end;
        justify-content: center;
      }
      &.pos-4 {
        top: 11px;
        height: 147px;
        width: 152px;
        right: 13px;

        display: flex;
        align-items: end;
        justify-content: center;
        @media (max-width: 540px) {
          top: 11px;
          height: 103px;
          width: 102px;
          right: 13px;
        }
      }
      &.pos-5 {
        top: 220px;
        left: 22px;
        width: 200px;
        @media (max-width: 540px) {
          top: 157px;
          left: 15px;
          width: 145px;
        }
      }
      &.pos-6 {
        top: 166px;
        height: 90px;
        width: 152px;
        right: 13px;

        @media (max-width: 540px) {
          top: 119px;
          height: 64px;
          width: 107px;
          right: 11px;
        }

        display: flex;
        align-items: end;
        justify-content: center;
      }
      &.pos-7 {
        top: 263px;
        height: 88px;
        width: 152px;
        right: 13px;

        @media (max-width: 540px) {
          top: 188px;
          height: 64px;
          width: 107px;
          right: 11px;
        }

        display: flex;
        align-items: end;
        justify-content: center;
      }
      &.pos-8 {
        top: 359px;
        height: 106px;
        width: 152px;
        right: 13px;

        @media (max-width: 540px) {
          top: 256px;
          height: 77px;
          width: 107px;
          right: 11px;
        }

        display: flex;
        align-items: end;
        justify-content: center;
      }
    }
    .position_bottom_1 {
      position: absolute;
      border: 1px solid red;
    }
    .position_top_2 {
      position: absolute;
      border: 1px solid red;
      top: 49px;
      height: 102px;
      width: 117px;
      &.item_1 {
        right: 40px;
      }
    }
  }
}

.locker-edit-active {
  .icon-edit-item {
    display: flex !important;
  }
}

.box-white-profile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 40px #00000019;
  border-radius: 20px;
  padding: 24px;
  .w-icon {
    display: flex;
    cursor: pointer;
    img {
      margin-right: 5px;
    }
    .redeem {
      text-decoration: underline;
      text-decoration-color: #dcdeed;
      font-size: 12px;
    }
  }

  .title {
    margin-bottom: 20px;
  }
  .box-stats {
    background: transparent linear-gradient(180deg, #fafafc 0%, #dfe5eb 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px;
    padding: 6px;
  }
  .box-credit {
    background: transparent linear-gradient(90deg, #93bced 0%, #b787fa 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px;
    padding: 6px;
  }
  .box-point {
    background: transparent linear-gradient(90deg, #f8afb2 0%, #f893cb 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px;
    padding: 6px;
    height: 100%;
    position: relative;
    // font-size: calc(4vw + 4vh + 2vmin);
    .point {
    }
  }
}
.slider_locker {
  .list-content .item {
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 100%;
    // position: relative;
    // @media (min-width: 768px) {
    //   max-width: 400px;
    // }
  }
}
.profile_team {
  color: #fff;
  display: flex;
  align-items: center;
  border: 0.5px solid #ffffff;
  border-radius: 30px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  justify-content: center;
  width: fit-content;
  padding: 4px 10px;
  line-height: 1.1;
  font-size: 12px;
  img {
    width: 20px;
    margin-right: 3px;
  }
}

.block-locker {
  position: relative;
  overflow: hidden;
  width: auto;
  .splide__arrow {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .splide__arrow {
      opacity: 1;
      visibility: visible;
    }
    .splide__arrow:disabled {
      display: none;
    }
  }
}

.btn-style-red {
  background: transparent linear-gradient(270deg, #db2718 0%, #aa2217 100%) 0%
    0% no-repeat padding-box;
  border-radius: 23px;
  padding: 9px 33px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #ffffff !important;
  }
}

.block-popup-accessory-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.block-popup-accessory {
  // display: none;

  overflow: auto;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(#000000, 0.3);
  width: 380px;
  @media (max-width: 540px) {
    width: 95%;
  }
  .head-red {
    font-size: 20px;
    color: #aa2217;
    text-align: center;
    margin: 0 0 30px 0;
  }
  .subhead-black {
    font-size: 14px;
    color: #000000;
    .count {
      font-weight: 300;
    }
  }
}
.list-inventory-disable {
  opacity: 0.3;
  .list-accessory label {
    cursor: not-allowed;
  }
}
.list-accessory {
  list-style-type: none;
  margin: 0 -10px;
  padding: 0;
  clear: both;
  display: flex;
  flex-wrap: wrap;
  li {
    margin: 0;
    padding: 10px;
    float: left;
    flex: 0 0 33.3333%;
    border-radius: 15px;
  }
  .accessory-item {
    background: #ebecf0;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      max-height: 80px;
    }
  }
  label {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  input:checked + label {
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;

      background: url("/frontend/img/icon_check_white.svg") center center
        rgba(#000000, 0.7);
      background-size: 20px auto;
      background-repeat: no-repeat;
    }
  }
  input:disabled + label {
    cursor: inherit;
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;

      background: url("/frontend/img/icon_check_white.svg") center center;
      background-size: 20px auto;
      background-repeat: no-repeat;
    }
  }
}

.splide__slide {
  width: 420px;
}

.style-locker {
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide {
    width: 420px;
    @media (max-width: 540px) {
      width: 300px;
    }
  }
  .style-locker-pagination {
    position: relative;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-prev:after {
    font-size: 16px;
    color: #ffffff;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background: rgba(#000000, 0.3);
    border-radius: 30px;
    height: 30px;
    width: 30px;
  }
  .swiper-pagination-bullet {
    background: #000000;
  }
}

.profile-l {
  // max-width: 336px;
  @media (min-width: 1024px) {
    margin-right: 100px;
  }
  .icon-like {
    width: 20px;
    // height: 20px;
  }
}
// .profile-r{
//   max-width: 686px;
// }

.locker-notice {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(#000000, 0.2);
}

.dialog-content-wrap {
  padding: 0 22px;
  margin: 0 -22px;
  overflow: auto;
}

.box-total-point {
  background: transparent linear-gradient(270deg, #56d6cc 0%, #93bced 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  padding: 5px;
  color: #fff;
  line-break: anywhere;
}
hr.profile-dash {
  border-top: 1px dashed #dcdeed;
}
