body {
  margin: 0;
}
.bg-auth {
  background-image: url(/frontend/img/bg_login@2x.png);
  min-height: 100vh;
  background-position: center;
  background-size: cover;
}
.full-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.box-auth {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 40px #69080033;
  border-radius: 40px;
  padding: 30px 30px;
  margin: 20px 0;
  // width: 620px;
  @media (min-width:758px) {
  padding: 70px 74px;
  margin: 100px 0;

    
  }
  .icon-line {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: fit-content;
    img {
      width: 25px;
      margin-right: 15px;
    }
  }
  .title {
    margin-bottom: 20px;
    span {
      background: linear-gradient(90deg, #db2718 0%, #aa2217 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  input.form-control {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .form-select {
    padding: 12px 20px;
    margin: 8px 0;
    display: block;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #c0c2d0;
  }
  .d-none {
    display: none;
  }
  .label-for-check {
    border: 1px solid #dcdeed;
    color: #dcdeed;
    border-radius: 5px;
    padding: 15px 10px;
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .check-with-label:checked + .label-for-check {
    border: 1px solid #000000;
    color: #000000;
  }
  .user-box {
    position: relative;
  }
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .password-toggle-icon i {
    font-size: 18px;
    line-height: 1;
    color: #333;
    transition: color 0.3s ease-in-out;
  }

  .line-w-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #dcdeed;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  .line-w-text span {
    background: #fff;
    padding: 0 10px;
  }

  a {
    color: #1155d5;
    font-size: 14px;
    font-weight: 300px;
    text-decoration: underline;
  }

  .icon-center{
    display: flex;
    justify-content: center;
    align-items: center;
    .ty{
      width: 60px;
    }
  }
}
