.accesory-main {
  .side-bar {
    .search-box {
      position: relative;
      input.form-search {
        width: 100%;
        padding: 10px 10px 10px 35px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #dcdeed;
        border-radius: 5px;
        box-sizing: border-box;
      }
      img {
        position: absolute;
        left: 8%;
        top: 40%;
      }
    }
  }
  .credit-box {
    background: transparent linear-gradient(90deg, #000000 0%, #aa2217 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000027;
    border-radius: 10px;
    color: #fff;
    padding: 5px;
    text-align: center;
    @media (min-width:768px) {
    line-height: 1.3;
    padding: 5px 28px;
      
    }
    img {
      width: 18px;
      height: 18px;
      margin-right: 3px;
    }
  }
  .d-flex-shop {
    .item-warpper {
      overflow-wrap: break-word;
    }
    .box-items-shop {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 25px #00000019;
      border-radius: 20px;
      padding: 15px 10px 60px 10px;
      height: 100%;
      position: relative;
      @media (min-width:768px) {
      padding: 15px 25px 60px 25px;
      }
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .box-items-outer {
        position: relative;
        margin-bottom: 20px;
        max-width: 220px;
        max-height: 220px;
        .new {
          background: transparent
            linear-gradient(270deg, #f6b753 0%, #fa610f 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 3px;
          padding: 1px 10px;
          color: #fff;
          font-size: 12px;
          position: absolute;
          bottom: -11px;
          line-height: 1.5;
          font-weight: 500;
          z-index: 1;
        }
        .new-icon {
          position: absolute;
          left: -3px;
          top: 0;
          width: 50px;
          z-index: 3;
        }
        .top-r {
          position: absolute;
          top: 0;
          right: 0;
          line-height: 1.5;
          font-weight: 500;
          z-index: 1;
          text-align: end;
          z-index: 1;
          .sale {
            background: transparent
              linear-gradient(270deg, #aa2217 0%, #db2718 100%) 0% 0% no-repeat
              padding-box;
            border-radius: 3px;
            padding: 1px 10px;
            color: #4b4b4b;
            font-size: 12px;
            margin-bottom: 8px;

            color: #FFFFFF;
          }
        }
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: calc(1 / 1) * 100%;
        }
        > .box-items-inner {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .price {
        position: absolute;
        bottom: 30px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }
      .alr-have {
        background: #d9f4e7 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 1px 5px;
        color: #33ba7c;
        display: flex;
        font-size: 10px;
        align-items: center;
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .accordion-custom {
    --bs-accordion-border-color: none;
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-btn-padding-y: 1rem;
    .accordion-body {
      padding: 0;
  }
    .accordion-item:first-of-type > .accordion-header .accordion-button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .accordion-button:not(.collapsed) {
      color: #ce2c1e;
      background-color: transparent;
      box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
        var(--bs-accordion-border-color);
    }
    .accordion-button:focus {
      z-index: 3;
      outline: 0;
      box-shadow: none;
    }
  }
}
.text-in-10px {
  text-indent: 10px;
}
.text-sale {
  color: #db2718;
  margin-right: 10px;
  &.old {
    text-decoration: line-through;
    color: #a8a8a8;
    font-size: 14px;
  }
}
.modal-accessory {
  padding: 42px 29px !important;
  @media (min-width:768px) {
    padding: 46px 85px !important;
  }
  .f-button {
    display: flex;
    @media (min-width:768px) {
      display: none;
    }
  }
  .box-item-confirm {
    // display: flex;
    background: #ebecf0 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 3px 15px;
    margin-top: 24px;
    margin-bottom: 31px;
    overflow: hidden;
    line-break: anywhere;
    .thumb-item-comfirm{
      width: 75px;
      height: 75px;
      .thumb-item-outer {
        position: relative;
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (1 / 1) * 100%;
        }
        > .thumb-item-inner {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
    // img {
    //   width: 75px;
    //   height: 75px;
    //   margin-right: 18px;
    // }
  }
  .btn-ls-cancle {
    border: 1px solid #aa2217;
    border-radius: 10px;
    opacity: 1;
    padding: 15px;
    // width: 190px;
    // height: 60px;
    margin-right: 15px;
  }
  .btn-ls-submit {
    border-radius: 10px;
    padding: 15px;
    // width: 190px;
    // height: 60px;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .icon-success {
    width: 50px;
    height: 50px;
  }
}
